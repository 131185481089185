/*global globalRouter, defaultState, defaultGetters,defaultActions, defaultMutation, VueRouter, VueAwesome, VueSelect, uuidv4, _*/
/* eslint-disable no-console */
window.__lodash__ = _.noConflict();
window.__underscore__ = _.noConflict();
window._ = window.__lodash__;
window.$Event = new Vue();


// Debugs Options
// For Development Only
//if(window.location.host == "shop.oppen.io") {
Vue.config.devtools = true;
window.__VUE_DEVTOOLS_HOST__ = 'localhost';
window.__VUE_DEVTOOLS_PORT__ = '8089';
//}
window.popStateDetected = false;
window.addEventListener('popstate', () => {
    window.popStateDetected = true;
});

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueTables.ClientTable, {useVuex: false, theme: 'bootstrap4', template: 'default'});

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('icon', VueAwesome);
Vue.component('v-select', VueSelect.VueSelect);

const store = new Vuex.Store({
    strict: false,
    modules: window.vuexModules,
    state: window.__lodash__.cloneDeep(defaultState),
    getters: window.__lodash__.cloneDeep(defaultGetters),
    actions: window.__lodash__.cloneDeep(defaultActions),
    mutations: window.__lodash__.cloneDeep(defaultMutation),
});

if (window.runCustomSettings)
    window.runCustomSettings();

let router = new VueRouter({
    mode: 'hash',
    //base: __dirname,
    routes: globalRouter,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.beforeResolve((to, from, next) => {
    if (from.name && to.name && to.name != from.name) {
        //console.log('Gets beforeResolve.',to, from);
        setTimeout(function () {
            store.commit("updateItemItemFlag");
        }, 500);
    }
    if (next)
        next();
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

router.afterEach(async (to, from) => {
    // delay hit to recover the real page title
    const callback = () => {
        if (!window.document.title) {
            setTimeout(() => setImmediate(callback), 1500);
            return;
        } else {
            window.scrollTo(0, 0);
            // Ignore some routes
            if (!to.name || (to.meta && to.meta.gtmIgnore)) {
                return;
            }
            // Facebook Page View Event
            if (window.fbq) {
                window.fbq('track', 'PageView');
            }
            //Google Analityc  content-view event
            // Dispatch vue event using meta gtm value if defined otherwise fallback to route name
            let name = window.document.title || to.meta.gtm || to.name;
            // Aqui agrego los casos en que quiere trackear con un title diferente
            switch (name) {
                case 'CategorySEO':
                    //console.log("Router",{to, from, name});
                    if (to.params.category && to.params.category.split("__").length > 0) {
                        name = to.params.category.split("__")[1];
                    }
                    break;
            }
            const additionalEventData = to.meta.gtmAdditionalEventData || {};
            let fullUrl = `${window.location.origin}${window.location.pathname}#${to.path}`;
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            let eventVirtualPage = {
                'event': 'virtualPageView',
                'virtualPageUrl': fullUrl,
                'virtualPagePath': to.path,
                'virtualPageTitle': name,
            };
            Object.assign({eventVirtualPage, additionalEventData});
            dataLayer.push({ecommerce: null});
            dataLayer.push(eventVirtualPage);
        }
    };
    setImmediate(callback, 1500);

});

Vue.mixin({
    data: function () {
        return {
            UIComponentID: uuidv4(),
            eventListener: []
        };
    },
    computed: {
        classContainer: function () {
            let settings = this.$store.getters.getSetting;
            if (settings && settings.PageCenterLayout)
                return ['container'];
            return ['container-fluid'];
        },
        isLogged: function () {
            return this.$store.getters.getIsLogin;
        },
        isPersonLiberator: function () {
            if (this.$store.getters.getIsLogin)
                return this.$store.state.liberatorCodes.length > 0 || this.$store.state.CenterLiberators.length > 0;
            return false;
        },
        isPersonOnlyModifier: function () {
            if (this.$store.getters.getIsLogin)
                return this.$store.state.onlyModifiedCodes.length > 0 || this.$store.state.CenterOnlyModifiedCodes.length > 0;
            return false;
        },
        isAdmin: function () {
            if (this.$store.getters.getIsLogin)
                return this.$store.getters.getUserIsAdmin;
            return false;
        },

        isLoading: function () {
            return this.$store.getters.getAppState != 'ready';
        },
        getAppState: function () {
            return this.$store.getters.getAppState;
        }
    },
    beforeDestroy() {
        //console.log('Destroy Elements');
        for (let eventObj of this.eventListener)
            this.unsubscribeEvent(eventObj.eventName, eventObj.callback);
        this.eventListener = [];
    },
    methods: {
        gtmTrackView(url, name, additionalEventData) {
            if (window.google_tag_manager) {
                let dataLayer = (window.dataLayer = window.dataLayer || []);
                let event = {
                    event: "content-view",
                    "content-name": url,
                    "content-view-name": name
                };
                Object.assign(event, additionalEventData);
                dataLayer.push(event);
            }
        },
        gtmTrackEvent(event = null, category = null, action = null, label = null, value = null, noninteraction = false) {
            if (window.google_tag_manager) {
                let dataLayer = (window.dataLayer = window.dataLayer || []);
                dataLayer.push({
                    event: event || "interaction",
                    target: category,
                    action: action,
                    "target-properties": label,
                    value: value,
                    "interaction-type": noninteraction
                });
            }
        },
        gtmTrackEcommerceItemList: _.throttle((items, currency, origin, stateTag) => {
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            currency = store.state.setting.Currency != "$" ? store.state.setting.Currency : "ARS";
            const event = {
                event: 'virtualViewItemList',
                ecommerce: {
                    virtualItemsList: items.map((x, index) => ({
                        item_id: x.Code,
                        item_name: x.Name,
                        item_list_name: origin,
                        item_list_id: origin.toLowerCase().replace(" ", "_"),
                        item_brand: x.Brand_format,
                        item_category: x.ClassificationPath[x.Classification] ? x.ClassificationPath[x.Classification].name : x.Classification,
                        price: x.Price,
                        quantity: 1,
                        coupon: null,
                        discount: null,
                        affiliation: null,
                        item_variant: null,
                        currency,
                        index,
                    })),
                    virtualItemListName: origin,
                    virtualItemListId: origin.toLowerCase().replace(" ", "_"),
                }
            };

            if (dataLayer) {
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push(event);
            }
        }, 2, {
            leading: false,
            trailing: true,
        }),
        gtmTrackEcommerceViewItem: _.throttle((itemDetail, currency) => {
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            currency = store.state.setting.Currency != "$" ? store.state.setting.Currency : "ARS";
            const event = {
                event: 'virtualViewItem',
                ecommerce: {
                    virtualCurrency: currency,
                    virtualItemsList: [{
                        item_id: itemDetail.Code,
                        item_name: itemDetail.Name,
                        item_brand: itemDetail.Brand_format,
                        item_category: itemDetail.ClassificationPath[itemDetail.Classification] ? itemDetail.ClassificationPath[itemDetail.Classification].name : itemDetail.Classification,
                        price: itemDetail.Price,
                        quantity: 1,
                        currency,
                    }],
                    virtualValue: itemDetail.Price,
                }
            };
            if (dataLayer) {
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push(event);
            }
        }, 2, {
            leading: false,
            trailing: true,
        }),
        gtmTrackEcommerceAddToCart: _.throttle((items, qty, currency, totalValue) => {
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            currency = store.state.setting.Currency != "$" ? store.state.setting.Currency : "ARS";
            if (!Array.isArray(items))
                items = [items];
            const event = {
                event: 'virtualAddToCart',
                ecommerce: {
                    virtualCurrency: currency,
                    virtualValue: totalValue,
                    virtualItemsList: items.map((x, index) => ({
                        item_id: x.Code,
                        item_name: x.Name,
                        item_list_name: origin,
                        item_list_id: origin.toLowerCase().replace(" ", "_"),
                        item_brand: x.Brand_format,
                        item_category: x.ClassificationPath[x.Classification] ? x.ClassificationPath[x.Classification].name : x.Classification,
                        price: x.Price,
                        quantity: qty,
                        coupon: null,
                        discount: null,
                        affiliation: null,
                        item_variant: null,
                        currency,
                        index,
                    })),
                }
            };
            if (dataLayer) {
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push(event);
            }
        }, 2, {
            leading: false,
            trailing: true,
        }),
        gtmTrackEcommerceRemoveFromCart: _.throttle((items, qty, currency, totalValue) => {
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            currency = store.state.setting.Currency != "$" ? store.state.setting.Currency : "ARS";
            if (!Array.isArray(items))
                items = [items];
            const event = {
                event: 'virtualRemoveFromCart',
                ecommerce: {
                    virtualCurrency: currency,
                    virtualValue: totalValue,
                    virtualItemsList: items.map((x, index) => ({
                        item_id: x.Code,
                        item_name: x.Name,
                        item_list_name: origin,
                        item_list_id: origin.toLowerCase().replace(" ", "_"),
                        item_brand: x.Brand_format,
                        item_category: x.ClassificationPath[x.Classification] ? x.ClassificationPath[x.Classification].name : x.Classification,
                        price: x.Price,
                        quantity: qty,
                        coupon: null,
                        discount: null,
                        affiliation: null,
                        item_variant: null,
                        currency,
                        index,
                    })),
                }
            };

            if (dataLayer) {
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push(event);
            }
        }, 2, {
            leading: false,
            trailing: true,
        }),
        gtmTrackEcommercePurchase: _.throttle((order) => {
            let dataLayer = (window.dataLayer = window.dataLayer || []);
            const currency = order.fields.Currency != "$" ? order.fields.Currency : "ARS";
            const event = {
                event: 'virtualPurchase',
                ecommerce: {
                    virtualCurrency: currency,
                    virtualValue: order.fields.Total,
                    virtualTransactionId: order.fields.SerNr,
                    virtualItemsList: order.fields.Items.map((orderItem, index) => {
                        const item = store.getters.getItemByCode(orderItem.fields.ArtCode);
                        if (item)
                            return {
                                item_id: item.Code,
                                item_name: item.Name,
                                item_brand: item.Brand_format,
                                item_category: item.ClassificationPath[item.Classification] ? item.ClassificationPath[item.Classification].name : item.Classification,
                                price: orderItem.fields.Price,
                                quantity: orderItem.fields.Qty,
                                discount: order.fields.DiscountDeal,
                                item_variant: item.Variants ? item.Variants : null,
                                currency: currency,
                                index
                            };
                        else {
                            return {
                                item_id: orderItem.fields.ArtCode,
                                item_name: orderItem.fields.Name,
                                item_brand: null,
                                item_category: null,
                                price: orderItem.fields.Price,
                                quantity: orderItem.fields.Qty,
                                discount: order.fields.DiscountDeal,
                                item_variant: null,
                                currency: currency,
                                index
                            };
                        }
                    }),
                }
            };
            //console.log("gtmTrackEcommercePurchase",event);
            if (dataLayer) {
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push(event);
            }
        }, 2, {
            leading: false,
            trailing: true,
        }),
        encodeToURL(value) {
            let urlString = (value + '').toString();
            return encodeURIComponent(urlString)
                .replace('!', '%21')
                .replace('\'', '%27')
                .replace('(', '%28')
                .replace(')', '%29')
                .replace('*', '%2A')
                .replace('%20', '+');
        },
        decodeFromURL(value) {
            let urlString = (value + '').toString();
            return decodeURIComponent(urlString)
                .replace('%21', '!')
                .replace('%27', '\'')
                .replace('%28', '(')
                .replace('%29', ')')
                .replace('%2A', '*')
                .replace('+', '%20');
        },
        urlSanitize(value) {
            let newValue = value.replace(/ /g, "-");
            newValue = newValue.replace(/\//, "");
            newValue = newValue.replace(/[\])}[{(]/g, "");
            return newValue;
        },
        resetScroll: function () {
            window.scrollTo(0, 0);
        },
        checkIsLogin() {
            return this.$store.getters.getIsLogin;
        },
        webUserCanDo(actionname, def = null) {
            for (let row of this.$store.state.webAccessGroup.Customs) {
                if (row.Name == actionname) {
                    //0 = allowed
                    //1 = denied
                    return row.Access === 0;
                }
            }
            if (def === null) {
                return !!this.$store.state.webAccessGroup.CustomsAccessType;
            }
            return def;
        },
        getUI2: function () {
            return uuidv4();
        },
        getUI: function () {
            return uuidv4();
        },
        capitalize: (s) => {
            if (typeof s !== 'string') return s;
            let lower = s.toLowerCase();
            //console.log(s,lower.charAt(0).toUpperCase() + lower.slice(1));
            return lower.toLowerCase().charAt(0).toUpperCase() + lower.slice(1);
        },
        encode: function (str) {
            let b64 = btoa(str);
            let a = b64.split("").reverse().join("");
            return a;
        },

        number_format: function (number, decimals, dec_point = ',', thousands_sep) {
            //Para mostrar los numeros con coma en lugar de punto, y con 2 decimales
            let sign = '';

            number = number * 1;
            if (number < 0) {
                number = number * (-1);
                sign = '-';
            }

            let str;

            if (decimals != null && decimals >= 0) {
                str = number.toFixed(decimals).toString().split('.');

            } else {
                str = number.toString().split('.');

            }

            let parts = [];
            for (let i = str[0].length; i > 0; i -= 3) {
                parts.unshift(str[0].substring(Math.max(0, i - 3), i));
            }
            str[0] = parts.join(thousands_sep ? thousands_sep : '.');
            return sign + str.join(dec_point ? dec_point : '.');
        },
        metricNumber: function (n) {

            n = n * 1;
            // let systemSettings = cm.SystemSettings.bringSync()

            // let decimal = systemSettings.DecimalSep;
            let decimal = ",";
            // let miles = systemSettings.ThousandSep;
            let miles = ".";

            let prefix = '';
            if (n < 0) {
                prefix = '-';
            }
            n = n.toFixed(2);

            let ranges = [
                {divider: 1e18, suffix: 'P'},
                {divider: 1e15, suffix: 'E'},
                {divider: 1e12, suffix: 'T'},
                {divider: 1e9, suffix: 'G'},
                {divider: 1e6, suffix: 'M'},
                {divider: 1e3, suffix: 'k'}
            ];
            let value;
            for (let i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                    value = this.number_format((n / ranges[i].divider).toFixed(2), null, decimal, miles);
                    return (value) + ranges[i].suffix;
                }
            }
            return prefix + this.number_format(n.toString(), null, decimal, miles);
        },
        emitEvent(eventName, value) {
            window.$Event.$emit(eventName, value);
        },
        subscribeEvent(eventName, callback) {
            window.$Event.$on(eventName, callback);
            this.eventListener.push({eventName: eventName, callback: callback});
        },
        unsubscribeEvent(eventName, callback) {
            window.$Event.$off(eventName, callback);
        },
        tr: function (stringOrigin) {
            if (Array.isArray(stringOrigin))
                return this.$store.getters.trWithParrams(stringOrigin);
            return this.$store.getters.tr(stringOrigin);
        },
        formatFloat: function (number) {
            try {
                if (isNaN(number))
                    return '--';
                return parseFloat(Math.round((parseFloat(number) + 0.00001) * 100) / 100).toFixed(2);
            } catch (err) {
                console.log('Error try to format number', number);
                return number;
            }
        }
    }
});

//eslint-disable-next-line no-unused-vars
const app = new Vue({
    store,
    router,
    watch: {
        '$route': 'redirectToUpdate',
        '$store.state.mustUpdateInfo': 'redirectToUpdate',
        '$store.state.session.fields.RequireUserInfo': 'redirectToUpdate',
        '$store.getters.getAddressConfirmation': function () {
            if (this.$store.getters.getAddressConfirmation) {
                setTimeout(() => $('#addressMap').modal(), 300);
            }
        }
    },
    computed: {
        isMobileDevice: function () {
            let check = false;
            // eslint-disable-next-line
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        showWelcomeMoldal: function () {
            if (this.$store.getters.getWelcomeMessage)
                $('#welcomeModal').modal('show');
            else
                $('#welcomeModal').modal('hide');
            return this.$store.getters.getWelcomeMessage;
        },
        latitude: function () {
            if (this.confirmAddress)
                return this.confirmAddress.Latitude;
            return null;
        },
        longitude: function () {
            if (this.confirmAddress)
                return this.confirmAddress.Longitude;
            return null;
        },
        screenlock: function () {
            return this.$store.getters.ScreenState;
        },
        screenText: function () {
            if (this.$store.state.screenMsg)
                return this.tr(this.$store.state.screenMsg);
            else
                return this.tr("Processing");
        },
        whatsappLink: function () {
            let settings = this.$store.getters.getSetting;
            if (settings && settings.WhatsAppNumber) {
                let baselink = "https://web.whatsapp.com/send?phone=";
                let whatsappPhone = settings.WhatsAppNumber.replace("+", "");
                let whatMesssage = '';
                if (settings.WhatsAppText) {
                    whatMesssage = settings.WhatsAppText;
                }
                if (this.isMobileDevice) {
                    baselink = "https://wa.me/";
                    return encodeURI(`${baselink}${whatsappPhone}?text=${whatMesssage}`);
                } else {
                    if (whatsappPhone) {
                        return encodeURI(`${baselink}${whatsappPhone}&text=${whatMesssage}`);
                    }
                }
            }
            return null;
        },
        confirmAddress: function () {
            if (this.$store.getters.getAddressConfirmation) {
                return this.$store.getters.getAddressConfirmation;
            }
            $('#addressMap').modal('hide');
            return null;
        }
    },
    methods: {
        closeWelcomeModal: function () {
            //$('#welcomeModal').modal('hide');
            this.$store.commit('changeStoreValue', {stateField: "showWelcomeMessage", value: false});
            return null;
        },
        redirectToUpdate: function () {
            if (this.$store.state.mustUpdateInfo && this.$store.state.session.fields.RequireUserInfo)
                this.$router.push('/genericuser');
        },
        confirmAddressTrigger(selectedAddress) {
            this.$store.commit("setDeliveryAddressConfirmation", selectedAddress);
        },
        viewInMap() {
            if (this.$store.getters.getAddressConfirmation) {
                setTimeout(() => $('#addressMap').modal(), 300);
                return this.$store.getters.getAddressConfirmation;
            }
            $('#addressMap').modal('hide');
            return null;
        },
        closeMap(emitEvent = true) {
            $('#addressMap').modal('hide');
            this.confirmAddress.normalize = false;
            if (emitEvent)
                this.emitEvent("address-relocation-cancel", this.confirmAddress);
            this.$store.commit("setDeliveryAddressConfirmation", null);
        },
        locationChange(latitude, longitude) {
            //console.log("Map Change Callback", latitude,longitude);
            this.confirmAddress.Latitude = latitude;
            this.confirmAddress.Longitude = longitude;
            this.confirmAddress.normalize = true;
            this.confirmAddress.CustomLocation = true;
        },
        confirmLocation() {
            if (this.confirmAddress) {
                this.confirmAddress.normalize = false;
                this.emitEvent("address-relocation", _.cloneDeep(this.$store.state.deliveryAddressConfirm));
                this.closeMap(false);
            }
        }
    },
    beforeCreate: async function () {
        //window.runCustomSettings();
        this.$store.dispatch('loadLanguage');
        await this.$store.dispatch('loadSession');
        this.subscribeEvent("addressNeedConfirmations", this.confirmAddressTrigger);
    },
    updated: function () {
        if (this.showWelcomeMoldal)
            $('#welcomeModal').modal('show');
        else
            $('#welcomeModal').modal('dispose');
        let self = this;

        $('#welcomeModal').on('hidden.bs.modal', (e) => {
            self.closeWelcomeModal();
        });

        if (this.$store.getters.getSession)
            this.$store.dispatch("resetAutoLogout");
    },
    template: `
      <div class="router-view">
        <a :href="whatsappLink" class="whatsapp pull-left" target="_blank" v-if="whatsappLink">
          <i class="fab fa-whatsapp  whatsapp-icon"></i>
        </a>
        <div class="blockscreen" v-if="screenlock">
          <div class="container full-screen">
            <div class="row full-screen justify-content-center align-items-center">
              <div class="text-center spinner-loading">
                <i class="icon fas fa-spinner fa-spin fa-3x"></i>
                <h5 class="mt-3">{{ screenText }}</h5>
              </div>
            </div>
          </div>
        </div>
        <template v-if="showWelcomeMoldal">
          <div id="welcomeModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="welcomeModalTitle"
               aria-hidden="true" data-toggle="modal">
            <div class="modal-dialog  modal-xl modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">
                  <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                          @click="closeWelcomeModal"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body mx-1">
                  <htmlSectionComponent :templateCode="$store.getters.getSetting.WelcomePage"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <NotificationComponent></NotificationComponent>
        <template v-if="confirmAddress">
          <div class="modal " id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{ tr("Address Confirmation") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5 class="text-justified">
                    {{ tr("For a better user experience and quick delivery, please confirm the location of the address on the map") }}
                    .</h5>
                  <div class="w-100 h-100">
                    <googleMapComponent :record="confirmAddress" :editMode="true"
                                        :saveChangeCallback="locationChange"></googleMapComponent>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="confirmLocation">{{ tr('Confirm') }}</button>
                  <button type="button" class="btn btn-secondary" @click="closeMap">{{ tr('Close') }}</button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <form autocomplete="off" onsubmit="return false" class="m-0">
          <router-view class="view oo-ecommerce"></router-view>
        </form>
      </div>`
}).$mount('#app');
